<template>
  <div class="container">
    <h1 class="termsTitle">Terms and Conditions</h1>
    <div class="termsContent">
      By using the Ivy-Way website, site visitors acknowledge Ivy-Way's Terms
      and Conditions, which describes how we collect, use, disclose, or store
      your information, as follows:
      <blockquote>
        <p>
          When you create an Ivy-Way user account, leave us a message, post on
          the forum, fill out a survey, sign up for an event, submit an
          application, or engage in other activities on our website, we may
          require your personal identifiable information, which is data that can
          be used to uniquely identify or contact a single person. Personal
          identifiable information we collect may include your name, street
          address, email address, landline phone number, cell phone number,
          contact preferences, and other identifiers. We will only use such
          information to verify your identity, to facilitate communication
          between you and Ivy-Way, and to provide our customers personalized
          information and services with Ivy-Way. You understand that your
          personal identifiable information is for internal use only, and
          Ivy-Way will not share, rent, or sell such information to a third
          party without your permission.
        </p>

        <p>
          When you engage in activities on our website, we may collect
          non-personal information, which is data in a form that does not permit
          direct association with any specific individual. We may collect, via
          tracking software like Google Analycis and in-house applications,
          non-personal information such as your language, city, state, zip code,
          area code, unique device identifier, location, time zone, occupation,
          academic information, personal anecdotes, and on-site browsing
          behaviour such as your visit duration, visit frequency, and site
          engagement. These information is collected and used for Ivy-Way to
          better understand visitors' and customers' behaviour, and thus improve
          our products and services. We will not share, rent, or sell such
          information to a third party without your permission.
        </p>

        <p>
          When you submit an application, you confirm that all information you
          provided are accurate, and all written work and documents are your own
          work. Additional official or unofficial proof of your academic
          background, work experience, test scores, or writing ability may be
          required upon request. You understand that inaccurate or purposefully
          information will result in termination of any appointed position, and
          may result in further legal action. Official transcript and/or up to
          two references from instructors, employers, employees, or friends may
          be requested, but are not required at the submission of the your
          application.
        </p>

        <p>
          Ivy-Way requires academic information, academic records, and written
          response and documents to determine applicant's fit and ability for
          the position(s) applied. Ivy-Way may permanently retain a copy,
          phsyically or in a storage device, of these written response and
          documents, and they will not be returned regardless of the hire
          decision. Unless the applicant opts out and informs Ivy-Way via email
          or letter within 1 month of application submission date, Ivy-Way
          becomes the co-owner of these documents, and modifications to the
          original documents will become the intellectual properties of Ivy-Way.
          Ivy-Way may anonymously reproduce, release, collect non-personal
          identifiable data, or publish these work for training, research,
          marketing, service, or publication purposes. If used, the reproduced
          or modified work will not contain any personally identifiable
          information. Such personally identifiable information will be removed
          or modified to protect the author's privacy and anonymity. In
          addition, applicants acknowledge and agree that they will not be
          entitled to any payment, now or in the future, in connection with the
          anything from this application. Applicants understand that granting
          Ivy-Way co-ownership of their written work is not a requirement for
          the application process, and opting out will not have any effect,
          positive or negative, to the application decision.
        </p>

        <p>
          Data security and safety are important to us. Ivy-Way has implemented
          appropriate safeguards to help prevent unauthorized access, maintain
          data security, and to make proper use of the information that we
          collect. However, no transmission of data over the Internet is
          guaranteed to be completely secure. Thus, while we strive to protect
          your personal information, Ivy-Way cannot absolutely guarantee the
          security of any information you provide to us, and does not assume
          liability for damages caused by a leak of our customers' information
          as a result of a malicious attack from a third party.
        </p>

        <p>
          This Terms and Conditions is a binding agreement under seal governed
          by the laws of the Commonwealth of Massachusetts.
        </p>
      </blockquote>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Terms and Conditions - Ivy-Way Academy"
    };
  }
};
</script>

<style scoped>
.termsTitle {
  margin: 20px 0px;
}

.termsContent {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  text-align: left;
  overflow: hidden;
  margin-bottom: 30px;
}

blockquote {
  font: 13px normal helvetica, sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 50px;
  padding-left: 15px;
  border-left: 3px solid #ccc;
}
</style>
